img.portrait {
    width: 100px;
    border-radius: 50%;
    float: left;
    margin-right: 20px
  }

.site-header {
    background: $brand-color;
    border: none;
}

.site-title, .site-title:visited {
    color: $background-color;
}

.post-meta {
    font-family: $alt-font-family;
}
